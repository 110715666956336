import { shape } from 'prop-types';
import {
  StoreFooterCTAs,
  ProductCollections,
  Section,
} from '@local/components';
import { useLocalizer } from '@nintendo-of-america/react-hooks';

import getPageBySlug from '@local/lib/contentful/api/getPageBySlug';
import getPageProps from '@local/lib/api/getPageProps';
import { storePagePropTypes } from '@nintendo-of-america/contentful-api/parsers';
import { storePageLocaleAlternates } from '@local/lib/helpers/hreflang';
import { PAGE_TYPES } from '@nintendo-of-america/contentful-api/constants';
import { LATAM_LOCALES } from '@shared/util/constants';
import * as C from '@local/components/pages/Store';
import {
  ScreenReaderOnly,
  Heading,
} from '@nintendo-of-america/component-library';
import { getGraph } from '@nintendo-of-america/graph-api';

export async function getServerSideProps(context) {
  const { res, locale } = context;
  const graph = getGraph(context);
  const page = await getPageBySlug({
    slug: '/store/',
    locale,
    pageContentType: PAGE_TYPES.STOREFRONT,
    graph,
  });

  if (!page && LATAM_LOCALES.has(locale)) {
    return {
      redirect: {
        permanent: false,
        destination: `/${locale}/store/games/`,
      },
    };
  }

  return getPageProps({
    page,
    analytics: {
      pageType: 'Merchandising',
      pageName: 'Store',
      pageCategory: 'Store',
    },
    res,
    localeAlternates: storePageLocaleAlternates,
  });
}

function Store({ page }) {
  const {
    backgroundA,
    backgroundB,
    banner,
    ctaCollection,
    featuredItemsA,
    featuredItemsB,
    featuredItemsC,
    hero,
    productCollections,
  } = page.content;
  const { text } = useLocalizer();

  return (
    <C.StoreWrap>
      <ScreenReaderOnly as="h1">{text('My Nintendo Store')}</ScreenReaderOnly>
      <Heading.NewLevel>
        <C.StoreHero
          content={{
            image: {
              alt: hero.asset.alt,
              assetPath: hero.asset.primary.assetPath,
              dimensions: hero.asset.primary.dimensions,
            },
            cta: hero.cta,
            heading: hero.heading,
            contentRating: hero.contentRating,
            contentDescriptors: hero.contentDescriptors,
            gamesShown: hero.gamesShown,
          }}
          background={{
            color: backgroundA.color,
            image: [{ url: backgroundA.image }],
          }}
        />
        {banner?.CONTENT_TYPE && <C.Banner content={banner} />}
        <C.FeaturedItemsA
          items={featuredItemsA.map((item) => ({
            url: item.url,
            heading: item.heading,
            assetPath: item.asset.primary.assetPath,
          }))}
        />
        <Section constrained small>
          <ProductCollections
            collections={productCollections.map((collection) => ({
              cta: {
                fields: {
                  ...collection.cta,
                },
              },
              heading: collection.heading,
              products: collection.list,
            }))}
          />
        </Section>
        <C.CTACollection
          collection={{
            heading: ctaCollection.heading,
            ctas: ctaCollection.list,
          }}
          background={{
            color: backgroundB.color,
            image: [{ url: backgroundB.image }],
          }}
        />
        <C.FeaturedItemsB
          items={featuredItemsB.map((item) => ({
            heading: item.heading,
            url: item.url,
            assetPath: item.asset.primary.assetPath,
          }))}
        />
        <C.FeaturedItemsC
          items={featuredItemsC.map((item) => ({
            heading: item.heading,
            url: item.url,
            assetPath: item.asset.primary.assetPath,
            background: {
              primary: {
                assetPath: item.background.image,
              },
            },
          }))}
        />
        <StoreFooterCTAs />
      </Heading.NewLevel>
    </C.StoreWrap>
  );
}

Store.propTypes = {
  page: shape({
    content: storePagePropTypes,
  }),
};

export default Store;
